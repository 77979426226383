/* ===== Google Font Import - Poppins ===== */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,800;1,300;1,500;1,600;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* ===== Colors ===== */
  --primary-color: #0e4bf1;
  // --panel-color: #3c393f;
  --panel-color: #fff;
  // --dasboard-color: #3c393f;
  --dasboard-color: #fff;
  // --rim-color: #312e34;
  --rim-color: #f8f8f8;
  --black-light-color: #707070;
  --black-light-color-box: #F1F1F6; // box dasboad
  --text-color: #000;
  --border-color: #e6e5e5;
  --toggle-color: #ddd;
  --box1-color: #4da3ff;
  --box2-color: #ffe6ac;
  --box3-color: #e7d1fc;
  --box4-color: #71bc68;
  --box5-color: #a37b53;
  --title-icon-color: #fff;
  --light-color: #fff;
  --707070-color: #707070;

  /* ====== Transition ====== */
  --tran-05: all 0.5s ease;
  --tran-03: all 0.3s ease;
  --tran-03: all 0.2s ease;
}

body.dark {
  --primary-color: #3a3b3c;
  --panel-color: #242526;
  --text-color: #ccc;
  --black-light-color: #ccc;
  --border-color: #4d4c4c;
  --toggle-color: #fff;
  --box1-color: #3a3b3c;
  --box2-color: #3a3b3c;
  --box3-color: #3a3b3c;
  --title-icon-color: #ccc;
}
#AdMin {
  height: auto;
  background-color: var(--rim-color);
  /* === Custom Scroll Bar CSS === */
  ::-webkit-scrollbar {
    width: 5px;
  }
  body.dark::-webkit-scrollbar-thumb:hover,
  body.dark .activity-data::-webkit-scrollbar-thumb:hover {
    background: #3a3b3c;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(153, 152, 152, 0.623);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(163, 162, 162, 0.74);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background: rgba(68, 68, 68, 0.5);
    border-radius: 10px;
  }

  .top .UilBars {
    color: #fff;
  }
  nav {
    position: fixed;
    height: calc(100%);
    width: 230px;
    padding: 10px 14px;
    background-color: var( --black-light-color-box);
    // border-right: 1px solid var(--border-color);
    transition: var(--tran-03);
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  nav.close {
    width: 73px;
  }
  nav .logo-name {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  nav .logo-name .logo_name {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    margin-left: -12px;
    transition: var(--tran-05);
  }
  nav.close .logo_name {
    opacity: 0;
    pointer-events: none;
  }
  nav .menu-items {
    margin-top: 40px;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu-items li {
    list-style: none;
    transition: all 0.3s;
  }
  .close .menu-items li {
    margin-bottom: 30px;
    transition: all 0.3s;
  }
  .menu-items li span {
    display: flex;
    align-items: center;
    height: 50px;
    text-decoration: none;
    position: relative;
  }
  .nav-links li span:hover:before {
    content: "";
    position: absolute;
    left: -7px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--text-color);
  }
  body.dark li span:hover:before {
    background-color: var(--text-color);
  }
  .menu-items li span .i,
  .logout-mode .link-name {
    font-size: 22px;
    margin: 5px 8px 0 8px;
    min-width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black-light-color);
    cursor: pointer;
  }
  .menu-items li span .link-name,
  .logout-mode .link-name {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-light-color);
    transition: var(--tran-05);
    cursor: pointer;
  }
  nav.close li span .link-name {
    opacity: 0;
    pointer-events: none;
  }
  .nav-links li span:hover .i,
  .nav-links li span:hover .link-name,
  .logout-mode span:hover .link-name,
  .logout-mode li span:hover .i {
    color: var(--text-color);
    font-weight: bold;
  }
  body.dark .nav-links li span:hover .i,
  body.dark .nav-links li span:hover .link-name {
    color: var(--text-color);
  }
  .menu-items .logout-mode {
    padding-top: 10px;
    border-top: 1px solid var(--border-color);
    font-size: 22px;
  }
  .menu-items .mode {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .menu-items .mode-toggle {
    position: absolute;
    right: 14px;
    height: 50px;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .mode-toggle .switch {
    position: relative;
    display: inline-block;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
  }
  .switch:before {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 15px;
    background-color: var(--panel-color);
    border-radius: 50%;
    transition: var(--tran-03);
  }
  body.dark .switch:before {
    left: 20px;
  }

  .dashboard {
    position: relative;
    left: 250px;
    background-color: var(--rim-color);
    // min-height: 100vh;
    width: calc(100% - 250px);
    transition: var(--tran-05);
    padding: 10px 30px 30px 30px;
  }
  nav.close ~ .dashboard {
    left: 73px;
    width: calc(100% - 73px);
  }
  .dashboard .top {
    display: none !important;
    position: fixed;
    top: 0;
    left: 250px;
    display: flex;
    width: calc(100% - 250px);
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    background-color: var(--panel-color);
    transition: var(--tran-05);
    z-index: 10;
  }
  .dashboard .top .UilBars {
    margin-left: 10px;
    cursor: pointer;
  }
  nav.close ~ .dashboard .top {
    left: 73px;
    width: calc(100% - 73px);
  }
  .dashboard .top .sidebar-toggle {
    font-size: 26px;
    color: var(--text-color);
    cursor: pointer;
  }
  .dashboard .top .search-box {
    position: relative;
    height: 45px;
    max-width: 600px;
    width: 100%;
    margin: 0 30px;
  }
  .top .search-box input {
    position: absolute;
    border: 1px solid var(--border-color);
    background-color: var(--panel-color);
    padding: 0 25px 0 50px;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    outline: none;
  }
  .top .search-box .i {
    position: absolute;
    left: 15px;
    font-size: 22px;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    color: var(--black-light-color);
    cursor: pointer;
  }
  .top img {
    width: 40px;
    border-radius: 50%;
  }

  //activity
  .dash-content .activity .activity-data {
    display: flex;
    // justify-content: space-between;
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 25px !important;
  }
  .activity-data .data {
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    .changeTypeUser {
      background-color: #ccc;
      position: absolute;
    }
  }
  .activity-data .data-title {
    font-size: 20px;
    font-weight: 500;
    color: #707070;
  }

  .activity-data .data .data-list {
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    margin-left: -12px;
    color: var(--text-color);
    width: 25vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .data.type {
    margin-top: 15px;
  }
  @media (max-width: 1000px) {
    nav {
      width: 73px;
    }
    nav.close {
      width: 250px;
    }
    nav .logo_name {
      opacity: 0;
      pointer-events: none;
      display: none;
    }
    nav.close .logo_name {
      opacity: 1;
      pointer-events: auto;
    }
    nav li span .link-name {
      opacity: 0;
      pointer-events: none;
    }
    nav.close li span .link-name {
      opacity: 1;
      pointer-events: auto;
    }
    nav ~ .dashboard {
      left: 73px;
      width: calc(100% - 73px);
    }
    nav.close ~ .dashboard {
      left: 250px;
      width: calc(100% - 250px);
    }
    nav ~ .dashboard .top {
      left: 73px;
      width: calc(100% - 73px);
    }
    nav.close ~ .dashboard .top {
      left: 250px;
      width: calc(100% - 250px);
    }
    .activity .activity-data {
      overflow-x: scroll;
    }
    .activity {
      margin-left: 20px;
    }
  }

  @media (max-width: 780px) {
    .dash-content .boxes .box {
      width: calc(100% / 2 - 15px);
      margin-top: 15px;
    }
  }
  @media (max-width: 560px) {
    .dash-content .boxes .box {
      width: 100%;
    }
    .dashboard .top .UilBars {
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }
    .menu-items li span .i {
      min-width: 30px;
      height: 100%;
    }
    .img_Avta {
      width: 30px;
      height: 30px;
    }
    .menu-items li{
      margin-top: 20px;
    }
    .dashboard .top .search-box {
      margin: 0 10px 0 12px;
    }
  }
  @media (max-width: 400px) {
    nav {
      width: 73px;
    }
    nav.close {
      width: 73px;
    }
    nav .logo_name {
      opacity: 0;
      pointer-events: none;
    }
    nav.close .logo_name {
      opacity: 0;
      pointer-events: none;
    }
    nav li span .link-name {
      opacity: 0;
      pointer-events: none;
    }
    nav.close li span .link-name {
      opacity: 0;
      pointer-events: none;
    }
    nav ~ .dashboard {
      // left: 0;
      width: 100%;
    }
    nav.close ~ .dashboard {
      left: 73px;
      width: calc(100% - 73px);
    }
    nav ~ .dashboard .top {
      left: 0;
      width: 100%;
    }
    nav.close ~ .dashboard .top {
      left: 0;
      width: 100%;
    }
    .dashboard .top .UilBars {
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }
    .menu-items li span .i {
      min-width: 30x;
      height: 100%;
      font-size: 24px;
    }
    .img_Avta {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
    .dashboard .top .search-box {
      margin: 0 10px 0 12px;
    }
  }
}
