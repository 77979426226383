.register {
  display: grid;
  place-items: center;
  // height: 100vh;
  background-color: #181c23;
  overflow: hidden;
  .font-poppins {
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  }

  /* ==========================================================================
     #GRID
     ========================================================================== */
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .row-space {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .col-2 {
    width: -webkit-calc((100% - 30px) / 2);
    width: -moz-calc((100% - 30px) / 2);
    width: calc((100% - 30px) / 2);
    float: left;
  }

  @media (max-width: 767px) {
    .col-2 {
      width: 100%;
    }
  }

  /* ==========================================================================
     #BOX-SIZING
     ========================================================================== */
  /**
   * More sensible default box-sizing:
   * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
   */
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  * {
    padding: 0;
    margin: 0;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  .logo_container {
    margin: 0 auto;
    width: 200px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .title {
    text-align: center;
    font-size: 20px;
    color: #f8f8f8;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .bg-blue {
    background: #2c6ed5;
  }

  .bg-red {
    background: #fa4251;
  }

  .bg-gra-01 {
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#fbc2eb),
      to(#a18cd1)
    );
    background: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a18cd1 100%);
    background: -moz-linear-gradient(bottom, #fbc2eb 0%, #a18cd1 100%);
    background: -o-linear-gradient(bottom, #fbc2eb 0%, #a18cd1 100%);
    background: linear-gradient(to top, #fbc2eb 0%, #a18cd1 100%);
  }

  /* ==========================================================================
     #SPACING
     ========================================================================== */
  .p-t-100 {
    padding-top: 100px;
  }

  .p-t-130 {
    padding-top: 130px;
  }

  .p-t-180 {
    padding-top: 180px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-100 {
    padding-bottom: 100px;
  }

  .m-r-45 {
    margin-right: 45px;
  }

  /* ==========================================================================
     #WRAPPER
     ========================================================================== */
  .wrapper {
    margin: 0 auto;
  }

  .wrapper--w960 {
    max-width: 960px;
  }

  .wrapper--w780 {
    max-width: 780px;
  }

  .wrapper--w680 {
    max-width: 680px;
  }

  /* ==========================================================================
     #BUTTON
     ========================================================================== */
  .btn {
    display: inline-block;
    line-height: 50px;
    padding: 0 50px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  }

  .btn--radius {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .btn--radius-2 {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .btn--pill {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  .btn--green {
    background: #57b846;
  }

  .btn--green:hover {
    background: #4dae3c;
  }

  .btn--blue {
    background: #4272d7;
  }

  .btn--blue:hover {
    background: #3868cd;
  }

  /* ==========================================================================
     #DATE PICKER
     ========================================================================== */
  td.active {
    background-color: #2c6ed5;
  }

  .table-condensed td,
  .table-condensed th {
    font-size: 14px;
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
    font-weight: 400;
  }

  .daterangepicker td {
    width: 40px;
    height: 30px;
  }

  .daterangepicker {
    border: none;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    display: none;
    border: 1px solid #e0e0e0;
    margin-top: 5px;
  }

  .daterangepicker::after,
  .daterangepicker::before {
    display: none;
  }

  .daterangepicker thead tr th {
    padding: 10px 0;
  }

  .daterangepicker .table-condensed th select {
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px;
    outline: none;
  }

  /* ==========================================================================
     #FORM
     ========================================================================== */
  input {
    outline: none;
    margin: 0;
    border: 2px solid #555;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: inherit;
    border-radius: 12px !important ;
  }

  .input--style-4 {
    line-height: 50px;
    background: #202632;
    // border: 2px solid black;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #f8f8f8;
  }
  .input--style-4:focus {
    border: 2px solid #e8e8e8;
  }

  .input--style-4::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #666;
  }

  .input--style-4:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    opacity: 1;
  }

  .input--style-4::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    opacity: 1;
  }

  .input--style-4:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
  }

  .input--style-4:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
  }

  .label {
    font-size: 16px;
    color: #8b8e98;
    text-transform: capitalize;
    display: block;
    margin-bottom: 5px;
  }

  .radio-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radio-container input:checked ~ .checkmark {
    background-color: #e5e5e5;
  }

  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-container .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #57b846;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e5e5e5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .input-group {
    position: relative;
    margin-bottom: 22px;
  }

  .input-group-icon {
    position: relative;
  }

  .input-icon {
    position: absolute;
    font-size: 18px;
    color: #999;
    right: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }

  /* ==========================================================================
     #SELECT2
     ========================================================================== */
  .select--no-search .select2-search {
    display: none !important;
  }

  .rs-select2 .select2-container {
    width: 100% !important;
    outline: none;
    background: #fafafa;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .rs-select2 .select2-container .select2-selection--single {
    outline: none;
    border: none;
    height: 50px;
    background: transparent;
  }

  .rs-select2
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 50px;
    padding-left: 0;
    color: #555;
    font-size: 16px;
    font-family: inherit;
    padding-left: 22px;
    padding-right: 50px;
  }

  .rs-select2
    .select2-container
    .select2-selection--single
    .select2-selection__arrow {
    height: 50px;
    right: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .rs-select2
    .select2-container
    .select2-selection--single
    .select2-selection__arrow
    b {
    display: none;
  }

  .rs-select2
    .select2-container
    .select2-selection--single
    .select2-selection__arrow:after {
    font-family: "Material-Design-Iconic-Font";
    content: "\f2f9";
    font-size: 24px;
    color: #999;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .rs-select2
    .select2-container.select2-container--open
    .select2-selection--single
    .select2-selection__arrow::after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .select2-container--open .select2-dropdown--below {
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #e0e0e0;
    margin-top: 5px;
    overflow: hidden;
  }

  .select2-container--default .select2-results__option {
    padding-left: 22px;
  }

  /* ==========================================================================
     #TITLE
     ========================================================================== */

  /* ==========================================================================
     #CARD
     ========================================================================== */
  .card {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
  }

  .card-4 {
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    // -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    // -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }

  .card-4 .card-body {
    padding: 57px 65px;
    padding-bottom: 65px;
    background-color: #202632;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 20px;
  }

  .register_btn {
    width: 100%;
    height: 40px;
    border: 0;
    background: #115dfc;
    border-radius: 7px;
    outline: none;
    color: #ffffff;
    cursor: pointer;
    font-weight: bold;
  }
  .register_btn:hover {
    color: #212121;
    background-color: #ffffff;
    border: 3px solid #115dfc;
    transition: 0.5s;
  }

  @media (max-width: 767px) {
    .card-4 .card-body {
      padding: 50px 40px;
    }
  }
}
