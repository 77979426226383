@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500;1,600&display=swap");

@mixin respond($breakpoint) {
  @if $breakpoint ==phoneSmall {
    @media (max-width: 330px) {
      @content;
    }
  }

  @if $breakpoint ==phone {
    @media (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint ==tablet {
    @media (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint ==desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

:root {
  --color-secondary: hsl(252, 100%, 90%);
  --color-success: hsl(120, 95%, 65%);
  --color-danger: hsl(0, 95%, 65%);
  --color-black: hsl(252, 30%, 10%);
  --color-btn: #2562c4;
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: all 300ms ease;
}
.btn-nav {
  background-color: var(--color-btn);
  color: #f8f8f8;
}
.btn-nav:hover {
  background-color: #fff;

  color: var(--color-btn);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@keyframes color {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.warpper {
  width: 100%;
  margin: auto;
  padding-bottom: 10px;
  background-color: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @include respond(phone) {
    width: 100%;
  }
  .link-name {
    margin-left: 5px;
  }
}

.warpper_MenuOutlined {
  color: #000;
  font-size: 20px;
  cursor: pointer;
  width: fit-content;
  display: none;

  @include respond(tablet) {
    display: none;
  }

  @include respond(phone) {
    display: block;
  }

  .togleMenuPhone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;

    .warpper_MenuOutlined_icon {
      padding: 10px 10px 0px 15px;
      color: #000;
    }
    .ant-dropdown {
      width: 300px;
    }
    .logOut_phone {
      width: max-content;
      margin-right: 10px;

      .btn-logOut {
        margin: 0px;
        margin-top:10px ;
        user-select: none;
        color: #fff;
        background-color: #2562c4;
      }

      .btn {
        font-size: 12px;
        padding: 8px 12px;
      }
    }
  }

  .togleMenuPhone_Menu {
    width: 256px;
    margin-left: 30px;
    background-color: #e5e5e5e5;
    border-radius: 8px;
    position: absolute;
    z-index: 1000000;
  }
}

#top {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px 50px;
  display: flex;
  justify-content: center;
  @include respond(phone) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
}

#top .logo h4 {
  font-size: 1.5rem;
  cursor: pointer;
  // font-weight: 700;
  margin-left: 50px;
  color: #fff;
  // animation: textAnimation 8s linear infinite;
}

#top .nav a {
  margin: 0 6px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  width: max-content;
  padding: 10px 12px;
  border-radius: 8px;
}

#top .nav a:hover {
  background-color: #2fcbc342;
  color: #fff;
  transition: 0.4s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#top .logOut .btn-logOut {
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;

  background-color: var(--color-btn);
  // margin-right: 50px;
}

#top .logOut .btn-logOut:hover {
  background-color: #fff;
  color: var(--color-btn);
  font-weight: bold;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* ========================== Create Post ====================== */

.profile-picture {
  width: 2.7rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
}
