@mixin respond($breakpoint) {
  @if $breakpoint == phoneSmall {
    @media (max-width: 400px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 440px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=Redressed&family=Ubuntu:wght@400;700&display=swap");
:root {
  --bg-bill: #f7ddc7;
  --white: #fff;
  --primary-table: #512d14;
  --secondary-clr: #5265a7;
  --gray: #c0bfbf5a;
}

#invoice {
  font-size: 12px;
  max-width: 500px;
  margin-top: 80px;
  // .drinkChild {
  //   border-bottom: 1px solid #ccc;
  // }
  .imgCoffe {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0 0;
    position: relative !important;
    top: 0;
    left: 0;
    @include respond(phone) {
      width: 400px;
    }
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .fixed {
    position: fixed;
  }
  .inforTable {
    top: 177px;
    left: 50px;
    width: 100%;
  }
  .absolute.dateBill {
    left: 125px;
    top: 228px;
    font-size: 7px;
    @include respond(phone) {
      left: 97px;
      top: 180px;
      font-size: 5px;
    }
    @include respond(phoneSmall) {
      left: 78px;
      top: 151px;
      font-size: 4px;
    }
  }
  .absolute.codeBill {
    left: 151px;
    top: 218px;
    font-size: 7px;
    @include respond(phone) {
      left: 115px;
      top: 173px;
      font-size: 5px;
    }
    @include respond(phoneSmall) {
      left: 92px;
      top: 147px;
      font-size: 4px;
    }
  }
  .absolute.nameUser {
    left: 258px;
    top: 228px;
    font-size: 7px;
    @include respond(phone) {
      left: 190px;
      top: 180px;
      font-size: 5px;
    }
    @include respond(phoneSmall) {
      left: 149px;
      top: 151px;
      font-size: 4px;
    }
  }
  .absolute.totalUser {
    left: 410px;
    top: 228px;
    font-size: 7px;
    @include respond(phone) {
      left: 297px;
      top: 180px;
      font-size: 5px;
    }
    @include respond(phoneSmall) {
      left: 230px;
      top: 151.5px;
      font-size: 4px;
    }
  }
  .scroll_table {
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    @include respond(phone) {
      max-height: 65px;
    }
    @include respond(phoneSmall) {
      max-height: 45px;
    }
  }
  .InfoDrinkChild {
    width: 100%;
  }
  .absolute.table {
    width: 75%;
    color: #000;
    top: 290px;
    left: 12.5%;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    background-color: transparent;
    @include respond(phone) {
      top: 220px;
    }
    @include respond(phoneSmall) {
      top: 175px;
    }

    .border-table {
      border: solid 1px #000;
      border-radius: 30px;
    }
    th {
      padding: 3px 0;
      color: #000;
      font-weight: bold;
      font-size: 10px;
      @include respond(phone) {
        font-size: 8px;
      }
      @include respond(phoneSmall) {
        font-size: 7px;
      }
    }

    th:nth-child(1) {
      width: 50%;
      text-align: start;
    }
    th:nth-child(2) {
      width: 12%;
      text-align: start;
    }
    th:nth-child(3) {
      width: 18%;
    }
    th:nth-child(4) {
      width: 20%;
    }
    .navDrinkChild {
      padding-left: 15px;
    }
    .InfoDrinkChild td {
      // border-bottom: 1px solid #000;
    }
    .drinkChild p {
      width: max-content;
      border-bottom: 1px solid #00000030 !important;
    }
    td {
      font-size: 10px;
      font-weight: 500 !important;
      @include respond(phone) {
        font-size: 8px;
      }
      @include respond(phoneSmall) {
        font-size: 7px;
      }
    }
    .InfoDrinkChild p {
      white-space: nowrap; /* Ngăn chặn văn bản từ việc xuống dòng */
      overflow: hidden; /* Ẩn văn bản vượt quá phần tử cha */
      text-overflow: ellipsis; /* Hiển thị ba chấm khi văn bản bị cắt */
    }
    .priceChild,
    .quantityChild,
    .totalChild {
      white-space: nowrap; /* Ngăn chặn văn bản từ việc xuống dòng */
      overflow: hidden; /* Ẩn văn bản vượt quá phần tử cha */
      text-overflow: ellipsis; /* Hiển thị ba chấm khi văn bản bị cắt */
    }
    .drinkChild {
      text-align: start;
      padding-left: 15px;
      width: 50%;
    }
    .priceChild {
      // text-align: start;
      padding-left: 0px;
      position: relative;
      left: -15px;
      @include respond(phone) {
        left: -10px !important;
      }
      @include respond(phoneSmall) {
        left: -10px !important;
      }
    }
    .totalChild {
      position: relative;
      left: 12px;
    }
    .quantityChild {
      position: relative;
      left: -15px;
      @include respond(phone) {
        left: -10px !important;
      }
      @include respond(phoneSmall) {
        left: -7px !important;
      }
    }
  }
  .totalPay {
    left: 215px;
    top: 487px;
    @include respond(phone) {
      left: 130px;
      top: 360px;
      font-size: 5px;
    }
    @include respond(phoneSmall) {
      left: 75px;
      top: 285px;
      font-size: 5px;
    }
    .SumPay tr {
      width: 165%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px;
      padding: 0;
      font-size: 10px;
    }

    .SumPay tr td:nth-child(1) {
      width: 70%;
      font-size: 10px;
      text-align: end;
      margin-right: 15px;
      @include respond(phone) {
        font-size: 8px;
      }
      @include respond(phoneSmall) {
        font-size: 7px;
      }
    }
    .SumPay tr td:nth-child(2) {
      width: 30%;
      font-size: 10px;
      text-align: start;
      @include respond(phone) {
        font-size: 8px;
      }
      @include respond(phoneSmall) {
        font-size: 7px;
      }
    }
  }
}
