@mixin respond($breakpoint) {
  @if $breakpoint == phoneSmall {
    @media (max-width: 330px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}
#FormBabyAdmin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: white;
  margin-top: -10px;
  .FormBabyAdmin {
    width: 45%;
    @include respond(phone) {
      width: 100%;
    }
    .FormBabyAdmin1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: max-content;
      padding: 25px;
      border-radius: 30px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        @include respond(phone){
          margin: 50px 0;
        }
    }
  }

  .ant-form-item-label label {
    color: black;
  }
  .printVoucher {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin: 0px 0 0 0px;
    width: max-content;
    height: max-content;
    padding: 23px 30px;
    border-radius: 30px;
    background-color: white;
    @include respond(phone){
      margin-bottom: 100px;
    }
    .bgImg {
      // width: 40vw;
      margin: 0 auto;
      position: relative;
      .imgVoucher {
        z-index: 1;
        position: relative;
        background-image: url("./Voucher_code.png");
        width: 520px;
        height: calc(500px / 1.97206704);
        margin: 30px auto;
        border-radius:5px ;
        @include respond(tablet) {
          margin-left: 0;
          width: 335px;
          height: 200px;
        }
        background-size: cover;
      }
      .codeNew {
        position: absolute;
        font-size: 11px;
        top: 165px;
        left: 245px;
        z-index: 1000000;
        color: #fff;
      }
    }
  }
}
