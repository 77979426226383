@mixin respond($breakpoint) {
  @if $breakpoint == phoneSmall {
    @media (max-width: 330px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}
#SearchBill {
  background-color: white;
  height: 135vh;
  .SearchBill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    margin: 0px auto;
    max-width: 700px;
    height: max-content;
    border-radius: 35px;
    padding: 25px;
    background-color: #1765ad;
    @include respond(phone) {
      flex-direction: column !important;
    }
  }
}
