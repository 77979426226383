@mixin respond($breakpoint) {
  @if $breakpoint == phoneSmall {
    @media (max-width: 330px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}
.NavChildAdmin {
  width: 100%;
  nav {
    width: 100%;
    padding: 20px 0 20px 0px;
    font-size: 22px;
    z-index: 10;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    background-color: var(--rim-color);
    @include respond(phone) {
      justify-content: space-between;
      flex-wrap: wrap;
    }
    // background: linear-gradient(
    //   45deg,
    //   #000,
    //   #000000b9,
    // );
    // background-size: 300% 300%;
    // animation: color 12s ease-in-out infinite;
    p {
      cursor: pointer;
      font-weight: 500;
      color: black;
      margin: 0 15px;
      font-size: 16px;
      letter-spacing: 1px;
      position: relative;
      display: inline-block;
      @include respond(phone) {
        font-size: 11px;
        color: #1677ff;
      }
    }
    .i {
      color: black;
      @include respond(phone) {
        display: none;
      }
    }
    span {
      margin-left: 15px;
      margin-top: 10px;
    }
  }
  .nav_link:hover .link-name,
  .nav_link:hover .i {
    color: var(--box1-color);
  }
}
