/* ===== Google Font Import - Poppins ===== */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,800;1,300;1,500;1,600;1,700&display=swap");
@mixin respond($breakpoint) {
  @if $breakpoint == phoneSmall {
    @media (max-width: 330px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if $breakpoint == bigDesktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.fs14 {
  font-size: 14px !important;
}
:root {
  /* ===== Colors ===== */
  --primary-color: #0e4bf1;
  // --panel-color: #3c393f;
  --panel-color: #fff;
  // --dasboard-color: #3c393f;
  --dasboard-color: #fff;
  // --rim-color: #312e34;
  --rim-color: #f8f8f8;
  --black-light-color: #707070;
  --black-light-color-box: #707070; // box dasboad
  --text-color: #000;
  --border-color: #e6e5e5;
  --toggle-color: #ddd;
  --box1-color: #4da3ff;
  --box2-color: #ffe6ac;
  --box3-color: #e7d1fc;
  --box4-color: #71bc68;
  --box5-color: #a37b53;
  --title-icon-color: #fff;
  --light-color: #fff;
  --707070-color: #707070;

  /* ====== Transition ====== */
  --tran-05: all 0.5s ease;
  --tran-03: all 0.3s ease;
  --tran-03: all 0.2s ease;
}

#AdMin {
  height: auto;

  /* === Custom Scroll Bar CSS === */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 12px;
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0b3cc1;
  }
  body.dark::-webkit-scrollbar-thumb:hover,
  body.dark .activity-data::-webkit-scrollbar-thumb:hover {
    background: #d7dbdf;
  }

  .dashboard {
    position: relative;
    left: 250px;
    background-color: var(--panel-color);
    min-height: 100vh;
    width: calc(100% - 250px);
    padding: 10px 14px;
    transition: var(--tran-05);
  }

  nav.close ~ .dashboard {
    left: 73px;
    width: calc(100% - 73px);
  }

  .dashboard .top {
    position: fixed;
    top: 0;
    left: 250px;
    display: flex;
    width: calc(100% - 250px);
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    background-color: var(--panel-color);
    transition: var(--tran-05);
    z-index: 10;
  }

  .dashboard .top .UilBars {
    margin-left: 10px;
    cursor: pointer;
  }

  nav.close ~ .dashboard .top {
    left: 73px;
    width: calc(100% - 73px);
  }

  .dashboard .top .sidebar-toggle {
    font-size: 26px;
    color: var(--text-color);
    cursor: pointer;
  }

  .dashboard .top .search-box {
    position: relative;
    height: 45px;
    max-width: 600px;
    width: 100%;
    margin: 0 30px;
  }

  .top .search-box input {
    position: absolute;
    border: 1px solid var(--border-color);
    background-color: var(--panel-color);
    padding: 0 25px 0 50px;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    outline: none;
  }

  .top .search-box .i {
    position: absolute;
    left: 15px;
    font-size: 22px;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    color: var(--black-light-color);
    cursor: pointer;
  }

  .top img {
    width: 40px;
    border-radius: 50%;
  }
  .dashboard .dash-content {
    background-color: var(--dasboard-color);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 12px;
  }
  .dash-content .title {
    display: flex;
    align-items: center;
    margin: 20px 0 30px 25px;
    height: 15vh;
  }

  .dash-content .title .i {
    position: relative;
    height: 35px !important;
    width: 35px !important;
    // background-color: var(--primary-color);
    border-radius: 6px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    margin-top: 20px;
  }

  .dash-content .title .text {
    font-size: 34px;
    font-weight: bold;
    color: var(--text-color);
    margin-left: 10px;
    margin-top: 25px;
  }

  .dash-content .boxes {
    @include respond(bigDesktop) {
      height: 20vh;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .boxes .number {
    @include respond(bigDesktop) {
      font-size: 22px !important;
    }
    font-weight: bold;
  }

  .dash-content .boxes .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    width: calc(100% / 3 - 15px);
    padding: 15px 20px;
    background-color: var(--box1-color);
    transition: var(--tran-05);
  }

  .dash-content .boxes .box_50 {
    height: 100%;
    width: calc(50% - 50px);
    background-color: var(--black-light-color-box);
    margin: 0 25px;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .dash-content .boxes .box_50:hover {
    background-color: #ececee;
  }

  .boxes .box .i {
    width: 75px;
    color: var(--text-color);
    font-size: 60px;
  }

  .boxes .box .text {
    white-space: nowrap;
    font-weight: 500;
    color: var(--text-color);
  }
  .boxes .fs14 {
    font-weight: bold !important;
    margin: 5px;
    font-size: 26px !important;
  }

  .boxes .box .number {
    font-size: 26px !important;
    font-weight: 500;
    color: var(--text-color);
  }

  .boxes .box.box2 {
    background-color: var(--box2-color);
  }

  .boxes .box.box3 {
    background-color: var(--box3-color);
  }

  .boxes .box.box4 {
    background-color: var(--box4-color);
  }

  .boxes .box.box5 {
    background-color: var(--box5-color);
  }
  .management-panel-user table tbody tr {
    margin-top: 20px !important;
    /* Điều chỉnh margin-top tùy ý */
  }

  .management-panel-user {
    display: flex;
    justify-content: center;
    max-height: 210px;
    overflow: auto;
    table {
      width: 85%;
      thead tr th {
        color: #120b07;
        font-size: 20px !important;
        font-weight: 600;
        text-align: start;
        width: 40%;
        margin: 20px;
      }
      tbody tr:hover td {
        color: #000;
      }

      tbody tr td {
        font-size: 20px !important;
        font-weight: 400;
        margin-top: 20px;
        color: #78767c;
        margin: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: start;
      }
    }
  }

  .ant-table-body .ant-table-cell,
  .ant-table-thead th {
    font-size: large !important;
  }
  @media (max-width: 1370px) {
    .ant-table-body .ant-table-cell,
    .ant-table-thead th {
      font-size: unset !important;
    }
    .dash-content .title .i {
      width: 30px !important;
      height: 30px !important;
    }
    .dash-content .title .text {
      font-size: 20px !important;
    }
    .boxes .box .i {
      font-size: 35px !important;
    }
    .boxes .box .text {
      font-size: 14px !important;
    }

    .boxes .box .number {
      font-size: 14px !important;
    }
  }
  @media (max-width: 1000px) {
    nav {
      width: 73px;
    }

    nav.close {
      width: 250px;
    }

    nav .logo_name {
      opacity: 0;
      pointer-events: none;
      display: none;
    }

    nav.close .logo_name {
      opacity: 1;
      pointer-events: auto;
    }

    nav li span .link-name {
      opacity: 0;
      pointer-events: none;
    }

    nav.close li span .link-name {
      opacity: 1;
      pointer-events: auto;
    }

    nav ~ .dashboard {
      left: 73px;
      width: calc(100% - 73px);
    }

    nav.close ~ .dashboard {
      left: 250px;
      width: calc(100% - 250px);
    }

    nav ~ .dashboard .top {
      left: 73px;
      width: calc(100% - 73px);
    }

    nav.close ~ .dashboard .top {
      left: 250px;
      width: calc(100% - 250px);
    }

    .activity .activity-data {
      // overflow-x: scroll;
    }
  }

  @media (max-width: 780px) {
    .dash-content .boxes .box {
      width: calc(100% / 2 - 15px);
      margin-top: 15px;
    }
  }

  @media (max-width: 560px) {
    .dash-content .boxes .box {
      width: 100%;
    }

    .dashboard .top .UilBars {
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }

    .menu-items li span .i {
      min-width: 30px;
      height: 100%;
    }

    .img_Avta {
      width: 30px;
      height: 30px;
    }

    .dashboard .top .search-box {
      margin: 0 10px 0 12px;
    }
  }

  @media (max-width: 400px) {
    nav {
      width: 0px;
    }

    nav.close {
      width: 73px;
    }

    nav .logo_name {
      opacity: 0;
      pointer-events: none;
    }

    nav.close .logo_name {
      opacity: 0;
      pointer-events: none;
    }

    nav li span .link-name {
      opacity: 0;
      pointer-events: none;
    }

    nav.close li span .link-name {
      opacity: 0;
      pointer-events: none;
    }

    nav ~ .dashboard {
      left: 0;
      width: 100%;
    }

    nav.close ~ .dashboard {
      left: 73px;
      width: calc(100% - 73px);
    }

    nav ~ .dashboard .top {
      left: 0;
      width: 100%;
    }

    nav.close ~ .dashboard .top {
      left: 0;
      width: 100%;
    }

    .dashboard .top .UilBars {
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }

    .menu-items li span .i {
      min-width: 30x;
      height: 100%;
      font-size: 24px;
    }

    .img_Avta {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }

    .dashboard .top .search-box {
      margin: 0 10px 0 12px;
    }
  }
}
